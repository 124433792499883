<template>
  <b-card>
    <validation-observer
      #default="{ invalid, handleSubmit }"
    >
      <b-form
        ref="form"
        :style="{height: trHeight}"
        class="repeater-form"
        @submit.prevent="handleSubmit(procSystemCodes)"
      >

        <!-- Actions -->
        <b-row class="mb-4">
          <b-col>
            <b-button
              variant="secondary"
              class="float-right"
              size="sm"
              type="submit"
              :disabled="invalid"
            >
              <span class="text-nowrap">Save Changes</span>
            </b-button>
          </b-col>
        </b-row>

        <system-codes-form
          v-for="(codem, index) in codems"
          :id="codem.id"
          :key="codem.id"
          ref="row"
          :codem="codem"
          :index="index"
          @remove-item="removeItem"
        />

      </b-form>
    </validation-observer>

    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      @click="repeateAgain"
    >
      <feather-icon
        icon="PlusIcon"
        class="mr-25"
      />
      <span>Add New</span>
    </b-button>

  </b-card>
</template>

<script>
import {
  BCard, BForm, BRow, BCol, BButton,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import { ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import SystemCodesForm from '../system-codes-form/SystemCodesForm.vue'
import store from '@/store'
import router from '@/router'

export default {
  components: {
    BCard,
    BForm,
    BRow,
    BCol,
    BButton,

    ValidationObserver,

    SystemCodesForm,

  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      codems: [{
        id: 1,
        tag: '',
        description: '',
        code: '',
        prevHeight: 0,
      }],
      nextId: 2,
      required,
    }
  },
  mounted() {
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    repeateAgain() {
      this.codems.push({
        id: this.nextId += this.nextId,
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].$el.offsetHeight)
      })
    },
    removeItem(index) {
      if (index == 0) {
        return
      }
      this.codems.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].$el.offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },

    async procSystemCodes() {
      try {
        const response = await store.dispatch('app-system-codes/procSystemCodes', { operation: 'C', codems: this.codems })
        if (!response.data.status) {
          return
        }
        router.push({ name: 'system-settings-system-codes-list' })
      } catch (error) {
        console.error(error)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>
